import { isHttpError } from '@digital-magic/react-common/lib/api'
import { RequestErrorHandler, RequestErrorMapper } from '@api/types'
import { defaultRequestErrorMapper } from '@api/utils'
import { useAdminContext } from '@pages/Admin/AdminContext'
import { useDefaultErrorHandler } from './useDefaultErrorHandler'

export const useAdminDefaultErrorHandler = (
  requestErrorMapper: RequestErrorMapper = defaultRequestErrorMapper
): RequestErrorHandler => {
  const { logout } = useAdminContext()
  const defaultErrorHandler = useDefaultErrorHandler(requestErrorMapper)

  return (e) => {
    if (isHttpError(e) && e.httpStatus === 401) {
      return logout()
    } else {
      defaultErrorHandler(e)
    }
  }
}
